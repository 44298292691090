<template>
    <div class="h-100 flex-display flex-column flex-center">
        <div>
            <img src="/img/logo_img.svg" alt="myonline therapy"/>
        </div>
        <h1 class="form-heading mt-5">Change Password</h1>
        <p class="text-xs-center w-343">Your password has been expired. It has been more than 90 days since you last changed your password.</p>
        <p class="app-error mt-2">
            {{error}}
        </p>
        <div class="w-343 mt-3" id="form">
            <div class="form-group">
                <label class="form-label">Current Password</label>
                <input class="input-text" type="password" v-model="currentPassword" placeholder="Enter you current Password">
            </div>
            <div class="form-group">
                <label class="form-label">New Password</label>
                <input class="input-text" type="password" v-model="password" placeholder="Enter New Password">
                <div class="error-block mt-3 text-left">
                    <p v-for="rule in rules" class="mb-0" :class="rule.class">{{rule.message}}</p>
                </div>
            </div>
            <button class="btn" :disabled="disable" @click="ChangePassword">Change Password</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ExpiredPasswordChange",
        data(){
            return{
                password: '',
                currentPassword:'',
                disable: true,
                error:'',
                error:'',
                disable: true,
                rules: [
                    { message:"Minimum 8 characters", regex:/.{8,}/,class:'text-dark' },
                    { message:"At least 1 number", regex:/[0-9]+/,class:'text-dark' },
                    { message:"At least 1 uppercase ",  regex:/[A-Z]+/,class:'text-dark' },
                    { message:'At least 1 lowercase', regex:/[a-z]+/,class:'text-dark' },
                ],
                errors:[]
            }
        },
        methods:{
            ChangePassword(){
                let self = this;
                let loader = this.$loading.show();
                this.$http.put(this.url+'password/expired/change', {
                    current_password: this.currentPassword,
                    password: this.password,
                }).then(response => {
                    loader.hide();
                    self.$toastr('success', response.data.message,'');
                    localStorage.setItem('password_expire_date',response.data.password_expire_date);
                    self.$router.push('/dashboard');
                }).catch(function (error) {
                    loader.hide();
                    self.error = error.response.data.message;
                });
            },
            updateStatus(){
                if( this.currentPassword !== '' && this.password !== '' && this.errors.length <=0 ) {
                    return false;
                }
                return true;
            },
        },
        watch:{
            currentPassword: {
                handler: function (val, oldVal) {
                    this.disable = this.updateStatus()
                },
                deep: true
            },
            password:{
                handler: function(val, oldVal){
                    this.errors =[];
                    for (let condition of this.rules) {
                        if (this.password === '') {
                            condition.class = 'text-dark';
                        } else if (!condition.regex.test(this.password)) {
                            condition.class = 'text-danger';
                            this.errors.push(condition.message);
                        } else {
                            condition.class = 'text-success';
                        }
                    }
                    this.disable = this.updateStatus()
                },
                deep: true
            },
        }
    }
</script>

<style scoped>

</style>